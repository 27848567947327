<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("fixit.title") }} {{ term }}
                <span class="d-block text-muted pt-2 font-size-sm">{{
                  $t("fixit.subtitle")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Button-->
              <v-flex class="mr-2">
                <fixit_edit_dialog />
              </v-flex>
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="items"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.term="{ item }">
                      <div class="fixit-steps">
                        <span
                          class="btn btn-danger text-left btn-arrow btn-arrow-right"
                          >{{ $t("fixit.list.table.from_this_query")
                          }}<br /><strong>{{ item.term }}</strong></span
                        >
                        <span
                          class="btn btn-success text-left btn-arrow btn-arrow-right"
                          v-if="isUrl(item.catalog_term)"
                          >{{ $t("fixit.list.table.to_url") }}<br /><strong>{{
                            item.catalog_term
                          }}</strong></span
                        >
                        <span
                          class="btn btn-success text-left btn-arrow btn-arrow-right"
                          v-else
                          >{{ $t("fixit.list.table.to_query") }}<br /><strong>{{
                            item.catalog_term
                          }}</strong></span
                        >
                      </div>
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19
                              11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333
                              17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          :placeholder="$t('fixit.list.table.search_field')"
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <a
                        @click="editItem(item)"
                        href="#"
                        class="btn btn-primary font-weight-bolder mr-2"
                      >
                        {{ $t("common.edit") }}</a
                      >
                      <a
                        @click="deleteItem(item)"
                        href="#"
                        class="btn btn-danger font-weight-bolder"
                      >
                        {{ $t("common.delete") }}</a
                      >
                    </template>
                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        {{ $t("common.nodata") }}
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fixit_edit_dialog from "./edit_dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  DELETE_FIXIT,
  PULL_FIXIT,
  PULL_FIXIT_LIST
} from "../../../core/services/store/fixit.module";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  components: { fixit_edit_dialog },
  data() {
    return {
      search: this.$route.query.term,
      options: {},
      headers: [
        {
          text: this.$t("fixit.list.table.headers.fixit_definition"),
          value: "term"
        },
        {
          text: this.$t("fixit.list.table.headers.rule_executed"),
          value: "executed"
        },
        {
          text: this.$t("fixit.list.table.headers.users_rule_executed"),
          value: "unique_executed"
        },
        {
          text: this.$t("fixit.list.table.headers.actions"),
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    onDateRangeChanged() {
      this.reloadData();
    }
  },
  methods: {
    isUrl: function(s) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(s);
    },
    editItem(item) {
      this.$store.dispatch(PULL_FIXIT, item.id);
    },
    deleteItem(item) {
      Swal.fire({
        title: this.$t("common.dialog_delete.title"),
        text: this.$t("common.dialog_delete.content"),
        cancelButtonText: this.$t("common.dialog_delete.cancel"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("common.dialog_delete.confirm")
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(DELETE_FIXIT, item.id).then(() => {
            this.reloadData();
            Swal.fire(this.$t("common.dialog_delete.success"), "", "success");
          });
        }
      });
    },
    reloadData() {
      const data = [];

      if (this.dateRange.startDate) {
        data.push(
          `start=${moment(this.dateRange.startDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.dateRange.endDate) {
        data.push(`end=${moment(this.dateRange.endDate).format("yyyy-MM-DD")}`);
      }

      this.$store.dispatch(PULL_FIXIT_LIST, data.join("&"));
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    onDateRangeChanged() {
      return this.dateRange;
    },
    ...mapState({
      errors: state => state.fixit.errors,
      message: state => state.fixit.message,
      hasMessage: state => state.fixit.hasMessage,
      items: state => state.fixit.items,
      loading: state => state.fixit.loading
    }),
    ...mapGetters(["currentProject", "dateRange"])
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("fixit.title") }]);
  }
};
</script>
